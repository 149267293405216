<template>
    <section id="services">
        <v-container class="fill-height px-15 py-15 pl-10 pr-15">
            <v-row align="center" justify="center">
                <h1
                    style="
                        font-size: 35px;
                        text-transform: uppercase;
                        font-weight: bold;
                    "
                    class="white--text pb-5 text-h6 text-md-h2 text-xl-h1 text-truncate"
                >
                    PRODUCT CATEGORIES
                </h1>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" class="down">
                    <v-sheet
                        elevation="2"
                        class="rounded-lg color1"
                        height="auto"
                        :width="$vuetify.breakpoint.xs ? 300 : 300"
                    >
                        <v-row>
                            <v-col cols="4"> </v-col>
                            <v-col
                                cols="4"
                                class="my-top"
                                align="center"
                                justify="center"
                            >
                                <div
                                    class="custom-border-color pa-6 ml-0 mr-0 white rounded-circle d-inline-block color1"
                                >
                                    <v-icon color="white" size="40"
                                        >food_bank</v-icon
                                    >
                                </div>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col cols="8" align="left" justify="center">
                                <p
                                    style="
                                        font-size: 20px;
                                        text-transform: uppercase;
                                        font-weight: bold;
                                    "
                                    class="white--text"
                                >
                                    Food Chemicals
                                </p>
                                <p
                                    v-for="food in food_chemicals"
                                    :key="food.item"
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>
                                    {{ food.item }}
                                </p>

                                <div class="mb-10"></div>
                                <v-btn
                                    block
                                    outlined
                                    to="contact-us"
                                    color="white"
                                >
                                    Get Started
                                </v-btn>
                                <div class="sizeIt"></div>
                                <v-row
                                    class="fix-row color2"
                                    :margin-right="
                                        $vuetify.breakpoint.sm
                                            ? '-20px'
                                            : 'auto'
                                    "
                                >
                                    <p style="font-size: 1.3rem"></p>
                                </v-row>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <!-- End of first Card!-->

                <v-col cols="12" sm="12" md="4" lg="4" class="down">
                    <v-sheet
                        elevation="2"
                        class="rounded-lg color1"
                        height="auto"
                        :width="$vuetify.breakpoint.xs ? 300 : 300"
                    >
                        <v-row>
                            <v-col cols="4"> </v-col>
                            <v-col
                                cols="4"
                                class="my-top"
                                align="center"
                                justify="center"
                            >
                                <div
                                    class="custom-border-color pa-6 ml-0 mr-0 white rounded-circle d-inline-block color1"
                                >
                                    <v-icon color="white" size="40"
                                        >reduce_capacity</v-icon
                                    >
                                </div>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col cols="8" align="left" justify="center">
                                <p
                                    style="
                                        font-size: 20px;
                                        text-transform: uppercase;
                                        font-weight: bold;
                                    "
                                    class="white--text"
                                    align="center"
                                >
                                    Solvents
                                </p>
                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>
                                    Ethanol
                                </p>
                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>Methanol
                                </p>
                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>Iso
                                    propyl alcohol (IPA)
                                </p>
                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>Glycerin
                                </p>
                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>Hydrogen
                                    peroxide
                                </p>

                                <p
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>Mono
                                    Ethylene glycol
                                </p>
                                <div class="mb-10"></div>
                                <v-btn
                                    block
                                    outlined
                                    to="/contact-us"
                                    color="white"
                                >
                                    Get Started
                                </v-btn>
                                <div class="sizeIt"></div>
                                <v-row
                                    class="fix-row color2"
                                    :margin-right="
                                        $vuetify.breakpoint.sm
                                            ? '-20px'
                                            : 'auto'
                                    "
                                >
                                    <p style="font-size: 1.3rem"></p>
                                </v-row>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-sheet>
                </v-col>

                <!-- End of First Our Service Card !-->
                <!-- End of first Card!-->

                <v-col cols="12" sm="12" md="4" lg="4" class="down">
                    <v-sheet
                        elevation="2"
                        class="rounded-lg color1"
                        height="auto"
                        :width="$vuetify.breakpoint.xs ? 300 : 300"
                    >
                        <v-row>
                            <v-col cols="4"> </v-col>
                            <v-col
                                cols="4"
                                class="my-top"
                                align="center"
                                justify="center"
                            >
                                <div
                                    class="custom-border-color pa-6 ml-0 mr-0 white rounded-circle d-inline-block color1"
                                >
                                    <v-icon color="white" size="40"
                                        >people</v-icon
                                    >
                                </div>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col cols="8" align="left" justify="center">
                                <p
                                    style="
                                        font-size: 18px;
                                        text-transform: uppercase;
                                        font-weight: bold;
                                    "
                                    class="white--text"
                                >
                                    Industrial Chemicals
                                </p>
                                <p
                                    v-for="indusrial in industrial_chemicals"
                                    :key="indusrial.item"
                                    style="font-size: 0.95rem"
                                    class="white--text"
                                >
                                    <v-icon color="white">done</v-icon>
                                    {{ indusrial.item }}
                                </p>
                                <div class="mb-15"></div>
                                <v-btn
                                    block
                                    outlined
                                    to="/contact-us"
                                    color="white"
                                >
                                    Get Started
                                </v-btn>
                                <div style="padding: 40px"></div>
                                <v-row
                                    class="fix-row color2"
                                    :margin-right="
                                        $vuetify.breakpoint.sm
                                            ? '-20px'
                                            : 'auto'
                                    "
                                >
                                    <p style="font-size: 1.3rem"></p>
                                </v-row>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    data: () => ({
        food_chemicals: [
            {
                item: 'Sodium benzoate',
            },
            {
                item: 'Citric acid anhydrous',
            },
            {
                item: 'Citric acid monohydrate',
            },
            {
                item: 'Potassium sorbate',
            },
            {
                item: 'Dextrose monohydrate',
            },
            {
                item: 'Maltodextrin',
            },
            {
                item: 'Sodium bicarbonate',
            },
            {
                item: 'Sorbic acid',
            },
            {
                item: 'Calcium propionate',
            },
            {
                item: 'Sodium citrate',
            },
            {
                item: 'Lactic acid',
            },
            {
                item: 'Malic acid',
            },
            {
                item: 'Aspartame',
            },
            {
                item: 'Acesulfame-k',
            },
            {
                item: 'Xanthan gum',
            },
            {
                item: 'Guar gum',
            },
            {
                item: 'Maize/corn starch',
            },
            {
                item: 'Glycerin monostearate',
            },
            {
                item: 'Monosodium glutamate',
            },
        ],
        industrial_chemicals: [
            {
                item: 'Calcium hypochlorite (Chlorine)',
            },
            {
                item: 'Hydrated Lime',
            },
            {
                item: 'Alluminium sulphate',
            },
            {
                item: 'Ethyl alcohol (Ethanol)',
            },
            {
                item: 'Quicklime',
            },
            {
                item: 'Soda ash',
            },
            {
                item: 'Boric acid ',
            },
            {
                item: 'Hydrochloric acid',
            },
            {
                item: 'Phosphoric acid',
            },
            {
                item: 'Acetic acid',
            },
            {
                item: 'Sulphuric acid ',
            },
            {
                item: 'Sulphonic acid ',
            },

            {
                item: 'Hydrogen peroxide',
            },
            {
                item: 'Sodium hypochlorite',
            },
            {
                item: 'Glycerin',
            },
            {
                item: 'Formaldehyde',
            },
        ],
    }),
};
</script>

<style scoped>
.my-top {
    margin-top: -70px;
}
.sizeIt {
    padding-bottom: 15px;
}
.fix-row {
    padding: 12px;
    margin-top: 20px;
    margin-right: -54px;
    margin-left: -54px;
    margin-bottom: -12px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.down {
    margin-top: 40px;
}
.custom-border-color {
    border-style: solid;
    border-color: #c7799d !important;
    border-width: 5px;
}
.color1 {
    background: #0b2571; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #0b2571,
        #0575ba
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #0b2571,
        #0575ba,
        #0b2571
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.color2 {
    background: #ffffff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #ffffff,
        #ffffff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #ffffff,
        #ffffff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#color3 {
    background: #ff416c; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #ff4b2b,
        #ff416c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #ff4b2b,
        #ff416c
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#color4 {
    background: #4cc4f4; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #4d51b2,
        #4cc4f4
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to top,
        #4d51b2,
        #4cc4f4
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#services {
    background-color: #0b2571;
}
</style>
